import React, { useEffect, useState, useRef } from "react";
import { Popper, Paper, MenuList, MenuItem, Typography, CircularProgress, IconButton } from "@material-ui/core";
import axios from "axios";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme) => ({
  notificationItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    paddingLeft: 30,
    cursor: "pointer",
    backgroundColor: "#e8e8e8",
    '&:hover': {
      backgroundColor: "#c6c8c7",
    },
  },
  unreadDot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "red",
    marginLeft: theme.spacing(1),
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  popper: {
    maxHeight: 200,
    overflowY: "auto",
  },
  messagePopper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    minWidth: 300,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: 'red'
  },
  subjectDescriptionBody: {
    padding: "5px 15px 5px 10px",
    fontSize: "15px",
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const NotificationsPopper = ({ anchorEl, open, onClose, setHasUnreadNotifications }) => {
  const classes = useStyles();
  const descriptionRef = useRef();
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
  const studentId = JSON.parse(Cookies.get('user_details')).studentId;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [messagePopperOpen, setMessagePopperOpen] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${api_link}/notifications/notifications/${studentId}`);
      const fetchedNotifications = response.data.data.reverse();
      setNotifications(fetchedNotifications);

      const hasUnread = fetchedNotifications.some(notification => !notification.readStatus);
      setHasUnreadNotifications(hasUnread);
    } catch (error) {
      console.error("Error fetching notifications:", error.response);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    setMessagePopperOpen(true);

    await axios.post(`${api_link}/notifications/read-notification`, {
      studentId: studentId,
      notificationId: notification.notificationId
    });

    fetchNotifications();
  };

  useEffect(() => {
    if (open && studentId) {
      fetchNotifications();
    }
  }, [open, studentId]);

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} onClose={onClose} style={{ marginTop: 25, width: '400px' }}>
        <Paper>
          <div className={classes.popper}>
            {loading ? (
              <div className={classes.loadingContainer}>
                <CircularProgress size={14} />
              </div>
            ) : (
              <MenuList>
                {notifications.map((notification) => {
                  const stripHtmlTags = (html) => {
                    const doc = new DOMParser().parseFromString(html, 'text/html');
                    return doc.body.textContent || '';
                  };

                  const plainText = stripHtmlTags(notification.message);

                  // Extract the first three words and add ellipsis if necessary
                  const messagePreview = (() => {
                    const words = plainText.split(' ');
                    return words.length > 3
                      ? words.slice(0, 3).join(' ') + '...'
                      : words.join(' ');
                  })();

                  return (
                    <MenuItem key={notification.notificationId} className={classes.notificationItem} onClick={() => handleNotificationClick(notification)}>
                      <Typography variant="body1">
                        {messagePreview}
                      </Typography>
                      {!notification.readStatus && (
                        <div className={classes.unreadDot} />
                      )}
                    </MenuItem>
                  );
                })}
                {notifications.length === 0 && !loading && (
                  <MenuItem>
                    <Typography variant="body1">No notifications</Typography>
                  </MenuItem>
                )}
              </MenuList>
            )}
          </div>
        </Paper>
      </Popper>

      {/* Message Popper for detailed view */}
      <Popper open={messagePopperOpen} anchorEl={anchorEl} onClose={() => setMessagePopperOpen(false)} style={{ marginTop: 25, width: '500px', height: 'auto' }}>
        <Paper className={classes.messagePopper}>
          <IconButton className={classes.closeButton} onClick={() => setMessagePopperOpen(false)}>
            <CloseIcon />
          </IconButton>
          <br />
          {selectedNotification && (
            <div>
              <Typography variant="subtitle">
                From: <b>{selectedNotification.senderName || 'Management'}</b>
              </Typography>
              <div style={{ marginTop: 15, maxHeight: '500px', overflowY: 'auto', overflowX: 'hidden', marginBottom: 15 }}>
                {/* <div ref={descriptionRef} className={classes.subjectDescriptionBody} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedNotification.message) }} /> */}
                <div style={{ fontSize: "13px" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedNotification.message) }} />
              </div>
            </div>
          )}
        </Paper>
      </Popper>

    </>
  );
};

export default NotificationsPopper;
