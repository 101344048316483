import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, InputAdornment, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from '../../assets/vlearned-final-logo.webp';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import Cookies from 'js-cookie';
import CircleSpinner from '../../components/CircleSpinner/CircleSpinner';

const Login = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
  const auth_link = process.env.REACT_APP_API_AUTH_LINK_VLEARNED;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${api_link}/users/login`, { email, password });

      if (response.data.status === 200) {
        const user_details = response.data.user;
        const user_info = {
          name: user_details.name,
          email: user_details.email,
          _id: user_details._id,
          phone: user_details.phone,
          status: user_details.status,
          role: user_details.role,
          user_token: user_details.user_token,
        };

        // Cookies.set('user_details', JSON.stringify(user_info));
        Cookies.set('user_details', JSON.stringify(user_info), { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });

        switch (user_details.role) {
          case 'Administrator':
            const organizationResponse = await axios.get(`${api_link}/organizations/user-organization/${user_details._id}`);
            const organization = organizationResponse.data.data[0];

            const adminSessionResponse = await axios.post(`${auth_link}/login`, { userId: user_info._id, role: user_info.role, email: user_info.email, name: user_info.name });

            if (adminSessionResponse.data.token) {
              // Cookies.set('session_token', adminSessionResponse.data.token);
              Cookies.set('session_token', adminSessionResponse.data.token, { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            }

            const combinedAdminDetails = { ...user_info, organizationId: organization._id, organizationType: organization.type };
            // Cookies.set('user_details', JSON.stringify(combinedAdminDetails));
            Cookies.set('user_details', JSON.stringify(combinedAdminDetails), { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            history.push(`/admin/${user_info._id}/next-steps`);
            break;
          case 'Super Administrator':
            const superAdminSessionResponse = await axios.post(`${auth_link}/login`, { userId: user_info._id, role: user_info.role, email: user_info.email, name: user_info.name });

            if (superAdminSessionResponse.data.token) {
              // Cookies.set('session_token', superAdminSessionResponse.data.token);
              Cookies.set('session_token', superAdminSessionResponse.data.token, { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            }

            history.push(`/super-admin/${user_info._id}/dashboard`);
            break;
          case 'Student':
            const studentResponse = await axios.get(`${api_link}/students/user-student/${user_details._id}`);
            const student = studentResponse.data.data;

            const studentSessionResponse = await axios.post(`${auth_link}/login`, { userId: user_info._id, role: user_info.role, roleId: student._id, organizationId: student.organizationId._id, organizationName: student.organizationId.name, email: user_info.email, name: user_info.name });

            if (studentSessionResponse.data.token) {
              // Cookies.set('session_token', studentSessionResponse.data.token);
              Cookies.set('session_token', studentSessionResponse.data.token, { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            }

            const combinedStudentDetails = { ...user_info, organizationId: student.organizationId._id, organizationType: student.organizationId.type, studentId: student._id };
            // Cookies.set('user_details', JSON.stringify(combinedStudentDetails));
            Cookies.set('user_details', JSON.stringify(combinedStudentDetails), { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            history.push(`/student/${student._id}/${student.organizationId.type}/${student.organizationId._id}/next-steps`);
            break;
          case 'Teacher':
            const teacherResponse = await axios.get(`${api_link}/instructors/user-instructor/${user_details._id}`);
            const teacher = teacherResponse.data.data;
            
            const teacherSessionResponse = await axios.post(`${auth_link}/login`, { userId: user_info._id, role: user_info.role, roleId: teacher._id, organizationId: teacher.organisationId._id, organizationName: teacher.organisationId.name, email: user_info.email, name: user_info.name });

            if (teacherSessionResponse.data.token) {
              // Cookies.set('session_token', teacherSessionResponse.data.token);
              Cookies.set('session_token', teacherSessionResponse.data.token, { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            }

            const combinedTeacherDetails = {
              ...user_info,
              organizationType: teacher.organisationId.type,
              organizationId: teacher.organisationId._id,
              teacherId: teacher._id
            };
            // Cookies.set('user_details', JSON.stringify(combinedTeacherDetails));
            Cookies.set('user_details', JSON.stringify(combinedTeacherDetails), { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            history.push(`/teacher/${teacher._id}/${teacher.organisationId.type}/${teacher.organisationId._id}/next-steps`);
            break;
          default:
            const userSessionResponse = await axios.post(`${auth_link}/login`, { userId: user_info._id, role: user_info.role });

            if (userSessionResponse.data.token) {
              // Cookies.set('session_token', userSessionResponse.data.token);
              Cookies.set('session_token', userSessionResponse.data.token, { expires: 7, domain: '.vlearned.com', sameSite: 'None', secure: true });
            }
            history.push(`/admin/${user_info._id}/next-steps`);
            break;
        }
      } else {
        setErrorMessage(`Error: ${response.data.error}`);
        setOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.data.error === "Account not activated, Activate first to login") {
        setErrorMessage('Account not activated, Activate first to login');
        setOpen(true);
        history.push('/activate-account');
      } else if ((error.response && error.response.data.error === "Operation `sessions.findOne()` buffering timed out after 10000ms") || (error.response && error.response.data.error === "Cannot read properties of null (reading 'create')")) {
        setErrorMessage('Error: Slow network connection, try again later.');
        setOpen(true);
        history.push('/login');
      } else {
        setErrorMessage('Error: ' + (error.response ? error.response.data.error : 'Network Error'));
        setOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userDetails = Cookies.get('user_details');
    const user = userDetails ? JSON.parse(userDetails) : null;

    const checkSessionStatus = async () => {
      setLoader(true);
      if (!user || user === null) {
        setLoader(false);
        return;
      }

      try {
        const sessionToken = Cookies.get('session_token');
        if (!sessionToken) {
          setLoader(false);
          return;
        }

        const response = await axios.get(`${auth_link}/status`, {
          headers: { Authorization: `Bearer ${sessionToken}` }
        });

        if (response.status !== 200) {
          setLoader(false);
          return;
        } else {
          switch (user.role) {
            case 'Super Administrator':
              history.push(`/super-admin/${user._id}/dashboard`);
              break;
            case 'Teacher':
              history.push(`/teacher/${user.teacherId}/${user.organizationType}/${user.organizationId}/next-steps`);
              break;
            case 'Student':
              history.push(`/student/${user.studentId}/${user.organizationType}/${user.organizationId}/next-steps`);
              break;
            case 'Administrator':
              history.push(`/admin/${user._id}/next-steps`);
              break;
            default:
              history.push(`/admin/${user._id}/next-steps`);
              break;
          }

          setErrorMessage("session.");
        }
      } catch (error) {
        setLoader(false);
        setErrorMessage("Error checking session. Please login again.");
      }
    };
    setLoader(false);

    checkSessionStatus();
  }, []);

  if (loader) return <CircleSpinner />

  return (
    <Container maxWidth="sm" style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '100vh' }}>
      <Box height={500} width={'100%'} gap={4} p={2}>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : errorMessage.startsWith('info:') ? 'info' : 'success'}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <center><Link to='/'><img src={logo} alt="logo" width={'60%'} /></Link></center>
          <p className='head'>Welcome!</p>
          <p className='subhead'>Login to VLEARNED to get started</p>

          <TextField type='email' label="Email" required variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: 10 }} />
          <TextField type={showPassword ? 'text' : 'password'} label="Password" required variant="outlined" fullWidth onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <p className='option2'><Link className='link' to='/forgot-password'>Forgot Password</Link></p>

          <Button variant="contained" color="primary" className='mt-7' onClick={handleLogin} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>

          <p className='option'>Don't have an account? <Link to='/register' className='link text-danger'>Register</Link></p>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
